import React from 'react';
import SEO from '../structure/seo';
import Layout from '../structure/layout';
import Index from '../structure/pages/neighborhood/lighthouse';


const TheLighthouse = () => {
    return (
        <Layout shadowNav={false}>
            <SEO title={'El Faro'} />
            <Index />
        </Layout>
    );
}

export default TheLighthouse;