import React, { useState } from 'react';
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components";
import { Container, Form, Col, InputGroup, Row, Button } from 'react-bootstrap';

const ContainerTitle = styled.section`
    background-color: #1E2E48;
    padding:2.5% 2.5%;
    margin-top: 7rem;
`
const P = styled.p`
    text-align: justify;
    color:#fff;
    padding-top: 2%;

`
const H2 = styled.h2`
    text-align: center;
    color:#fff;
    font-size: 3rem;
`
const H3 = styled.h3`
    text-align: center;
    color:#C1292F;
    font-size: 1.2rem;
`
const H4 = styled.h3`
    text-align: center;
    color:#000;
    font-size: 1rem;
`

const Index = () => {
    return (
        <>
            <ContainerTitle>
                <H2>El Faro</H2>
            </ContainerTitle>
            <Container>
                <nav aria-label="breadcrumb" className="mt-5">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a style={{ color: "#000", textDecoration: "none" }} href="/">INICIO</a></li>
                        {/* <li className="breadcrumb-item"><a style={{ color: "#000", textDecoration: "none" }} href="/">BARRIOS</a></li> */}
                        <li className="breadcrumb-item active" aria-current="page">EL FARO</li>
                    </ol>
                </nav>
                <Row className="justify-content-center">
                    <Col lg={8} className="text-center">
                        <StaticImage src="../../../../images/logo-01.png" />
                        <H2 style={{ color: "#1E2E48" }} className="mt-5">EL FARO</H2>
                        <H3>CLUB NAUTICO</H3>
                        <H4>@elfaroclubnautico</H4>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-5">
                    <Col lg={12} className="text-justify">
                        <P className="text-dark">Al Noreste de “EL SAN JUAN – Villa Náutica” con vistas al río en toda su extensión, se encuentra el “El Faro- Club Náutico”. El mismo ha sido construido para brindar servicio a los 5 Barrios mencionados y receptivo a todos los amantes de la naturaleza con fines sociales y recreativos. Su ingreso puede ser por camino de acceso principal a la Villa o por agua a través del muelle propio. Ofrece un espacio exclusivo donde se puede disfrutar de un día de paseo en catamarán o lanchas particulares, de la gastronomía regional en el resto bar, alojamiento exclusivo en la torre del propio faro, relax en balcones al río, piscinas, parrillas con quinchos, parque con juegos infantiles, eventos en el SUM del Club privado y paseo por senderos que recorre su laguna con peces y plantas acuáticas.</P>
                    </Col>
                </Row>
                <Row>
                    <Col lg={7}>
                        <StaticImage src="../../../../images/Lighthouse.png" className="mt-5" />
                    </Col>
                    <Col lg={5}>
                        <StaticImage src="../../../../images/LighthouseTable.png" className="mt-5" />
                    </Col>
                    <Col lg={12}>
                        <StaticImage src="../../../../images/LighthouseTwo.png" className="mt-5" />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Index;